<template>
  <div>
    <!-- Exam Card  -->
    <b-card class="material-card mb-1 p-1">
      <!-- Card Header -->
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar rounded="lg" variant="light-primary">
              <i class="card-icon ti ti-clipboard-list "></i>
            </b-avatar>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column ">
                <b-card-subtitle class="text-muted ">Ujian</b-card-subtitle>
                <b-card-title class="font-weight-bolder small-gap-bottom">
                  UAS Geografi</b-card-title
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-badge variant="light-secondary">
            Senin 10 Oktober 2022, 10:00 WIB
          </b-badge>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col md="8">
          <!-- Exam Detail -->
          <div class="d-flex justify-content-between mb-2">
            <!-- Teacher Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Guru Kelas
              </h6>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    src="/img/13-small.d796bffd.png"
                    text="avatarText('Bambang')"
                    variant="`light-primary`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  Sukmadi, S.Pd.
                </span>
                <small class="text-muted">NIP. 1404591288123</small>
              </b-media>
            </div>

            <!-- Class Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Kelas
              </h6>
              <span>
                Geografi
              </span>
            </div>

            <!-- Progress Info -->
            <div class="w-30">
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Progress
                </h6>
                <h6 class="font-weight-bolder small-gap-bottom">
                  05:00 Menit / 2 Jam
                </h6>
              </div>
              <b-progress class="small-gap-top" value="2" max="100" />
            </div>
          </div>

          <!-- Exam Content -->
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar class="small-gap-right" variant="primary">
                02
              </b-avatar>
              <p class="font-weight-normal">
                {{ exam.question }}
              </p>
            </div>

            <!-- Exam Answer -->
            <div class="exam-answer-options">
              <b-form-group v-for="option in exam.options" :key="option.id">
                <b-input-group class="small-gap-bottom">
                  <b-input-group-prepend is-text>
                    <b-form-radio
                      v-model="exam.answer"
                      :value="option.id"
                      plain
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    @click="exam.answer = option.id"
                    readonly
                    :value="option.text"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <h6 class="font-weight-bolder small-gap-bottom">
            Nomor Soal
          </h6>

          <!-- Exam Navigation -->
          <div>
            <b-row>
              <b-col>
                <div class="d-flex flex-wrap">
                  <b-button
                    class="round-button text-center"
                    v-for="(nav, index) in examNavigations"
                    :key="index"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="getExamNavigationVariant(nav)"
                    size="sm"
                    pill
                  >
                    {{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <div class="d-flex justify-content-around">
                  <b-badge class="px-1" variant="warning">Ditandai</b-badge>
                  <b-badge class="px-1" variant="secondary">Dijawab</b-badge>
                  <b-badge class="px-1" variant="danger">Belum Dijawab</b-badge>
                  <b-badge class="px-1" variant="primary">Sekarang</b-badge>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="12" class="border-top pt-1 mt-1">
          <div class="d-flex justify-content-start">
            <b-button variant="outline-primary">
              <i class="align-middle ti ti-arrow-left"></i>
              <span class="align-middle ml-1">Kembali</span>
            </b-button>
            <b-button variant="warning" class="ml-1">
              <i class="align-middle ti ti-star"></i>
              <span class="align-middle ml-1">Tandai</span>
            </b-button>
            <b-button variant="primary" class="ml-1">
              <span class="align-middle mr-1">Selanjutnya</span>
              <i class="align-middle ti ti-arrow-right"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BInputGroup,
    BFormInput,
    BFormRadio,
    BInputGroupPrepend,
    BAvatar,
    BMedia,
    BProgress,
  },
  data() {
    return {
      examNavigations: [
        {
          questionId: 1,
          questionNumber: 1,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 2,
          questionNumber: 2,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 3,
          questionNumber: 3,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 4,
          questionNumber: 4,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 5,
          questionNumber: 5,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 6,
          questionNumber: 6,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 7,
          questionNumber: 7,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 8,
          questionNumber: 8,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 9,
          questionNumber: 9,
          isAnswered: false,
          isMarked: false,
        },
        {
          questionId: 10,
          questionNumber: 10,
          isAnswered: false,
          isMarked: false,
        },
      ],
      exam: {
        questionNumber: 2,
        questionId: 2,
        question:
          'Secara fisiografis, wilayah laut Indonesia dibagi menjadi tiga wilayah yaitu Paparan Sunda, Paparan Sahul, dan zona transisi. Paparan Sunda terletak di bagian barat Indonesia dengan ciri … dan meliputi daerah perairan … ',
        options: [
          {
            id: 1,
            text: 'Terdapat palung; Laut Banda dan Laut Aru',
            isSelected: false,
          },
          {
            id: 2,
            text: 'Laut dalam; Laut Jawa dan Selat Malaka',
            isSelected: false,
          },
          {
            id: 3,
            text: 'Laut dangkal; Laut Jawa dan Laut Banda',
            isSelected: false,
          },
          {
            id: 4,
            text: 'Terdapat palung; Laut Jawa dan Laut Cina Selatan',
            isSelected: false,
          },
          {
            id: 5,
            text: 'Laut dangkal; Selat Malaka dan Laut Cina Selatan',
            isSelected: false,
          },
        ],
        answer: null,
      },
    }
  },
  computed: {},
  methods: {
    getExamNavigationVariant(nav) {
      if (nav.questionNumber <= this.exam.questionNumber) {
        // if current question
        if (nav.questionNumber === this.exam.questionNumber) {
          return 'primary'
        }

        // if marked or answered
        if (!nav.isMarked) {
          if (!nav.isAnswered) {
            return 'danger'
          }
          return 'secondary'
        }
        return 'warning'
      }
      return 'outline-primary'
    },
  },
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}
</style>
